/* global I18n */

import onmount from 'onmount';
import TimeMe from 'timeme.js/timeme';
import Rails from '@rails/ujs';
import snackbar from '@components/snackbar';
import { MDCSlider } from '@material/slider';
import { debounce } from '@modules/custom';
import {
  showScore, updatePaginationTile, showEditedBy
} from '@modules/grading/scoring';

onmount('[data-js-slider-panel]', function () {
  const self = this;
  const {
    editable, assignmentType
  } = this.dataset;
  if (editable === 'false') return;

  const keysTimed = [];
  const keyDelay = 350;
  let keyPressed = 0;

  const input = this.querySelector('[data-js-submission-score-input]');
  const maxValue = parseFloat(input.getAttribute('max'));
  const undoGrading = this.querySelector('[data-js-undo-grading');
  const submissionForm = this.querySelector('[data-js-slider-form]');
  const gradingTimeInput = self.querySelector('[data-js-grading-time]');
  const sliderEl = this.querySelector('.mdc-slider');
  if (!sliderEl.MDCSlider) {
    sliderEl.MDCSlider = new MDCSlider(sliderEl);
    sliderEl.setAttribute('data-mdc-auto-init-state', 'initialized');
  }

  const submitForm = debounce(() => {
    gradingTimeInput.value = parseFloat(gradingTimeInput.value || 0) + TimeMe.getTimeOnCurrentPageInSeconds();

    Rails.fire(submissionForm, 'submit');
  }, 250);

  function handleSliderInput() {
    const roundedValue = parseFloat(sliderEl.MDCSlider.getValue()).toFixed(1);
    sliderEl.MDCSlider.setValue(roundedValue);
    input.numeric.set(roundedValue);

    if (document.activeElement) document.activeElement.blur();
  }

  function handleInputFocus() {
    this.select();
  }

  function handleInputChange() {
    sliderEl.MDCSlider.setValue(this.value);
    submitForm();
  }

  function handleUndoGradingClick(e) {
    if (this.querySelector("button[type='submit']").disabled) return;

    e.preventDefault();

    setTimeout(function () { // eslint-disable-line
      Rails.fire(this, 'submit');
    }.bind(this), keyDelay * 2);
  }

  function handleUndoGradingResponse(e) {
    const [data] = e.detail;
    const score = data.score || null;
    showScore(data.id, score);
    updatePaginationTile(data.id, data.graded, data.score, data.points);

    input.numeric.wipe();
    sliderEl.MDCSlider.setValue(Number.isNaN(parseFloat(score)) ? 0 : score);

    snackbar(I18n.t('js.gradings.grading_undone'));
    undoGrading.querySelector("button[type='submit']").disabled = true;
  }

  function handleSpacebar() {
    if (parseFloat(input.value) === maxValue) return;

    input.value = maxValue;
    sliderEl.MDCSlider.setValue(maxValue);
    submitForm();
  }

  function handleSuccess(e) {
    const data = e.detail[0];
    showScore(data.id, data.score);
    updatePaginationTile(data.id, data.graded, data.score, data.points);
    showEditedBy(self, data.edited_by);

    undoGrading.querySelector("button[type='submit']").disabled = false;
  }

  function handleError() {
    if (navigator.onLine) {
      snackbar(I18n.t('js.gradings.failed_score_save'), true);
    } else {
      snackbar(I18n.t('js.gradings.no_internet'), true);
    }
  }

  function handleKeyUp(e) {
    if (self.closest('.d-none')) return;
    if (e.metaKey || e.ctrlKey || e.shiftKey || e.altKey) return;
    if (['INPUT', 'TEXTAREA', 'MATH-FIELD'].includes(e.target.tagName)) return;
    if (['radio', 'checkbox'].includes(e.target.type)) return;
    if (e.target.closest('.redactor-in')) return;
    if (e.target.closest('.x-spreadsheet')) return;

    if (!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', ',', ' '].includes(e.key)) {
      return;
    }

    if (e.key === ' ') {
      handleSpacebar(e);
    }
    let keyTemp = e.key;
    if (e.key === ',') keyTemp = '.';

    const currentTime = new Date().getTime();
    if (currentTime - keyPressed > keyDelay && keysTimed.length > 0) {
      keysTimed.splice(0, keysTimed.length);
    }
    if (currentTime - keyPressed < keyDelay || keysTimed.length === 0) {
      keysTimed.push(keyTemp);
    }
    keyPressed = currentTime;

    const value = Math.min(parseFloat(keysTimed.join('')), maxValue);

    if (!Number.isNaN(value)) {
      input.numeric.set(value);
      sliderEl.MDCSlider.setValue(value);
      submitForm();
    }
  }
  this.handleKeyUp = handleKeyUp;

  input.addEventListener('focus', handleInputFocus);
  input.addEventListener('input', debounce(handleInputChange, 250));
  input.addEventListener('paste', handleInputChange);
  sliderEl.MDCSlider.listen('MDCSlider:input', handleSliderInput);
  sliderEl.MDCSlider.listen('MDCSlider:change', submitForm);
  if (undoGrading) {
    undoGrading.addEventListener('click', handleUndoGradingClick);
    undoGrading.addEventListener('ajax:success', handleUndoGradingResponse);
  }
  if (assignmentType !== 'Assessment' && assignmentType !== 'Appraisal') {
    document.addEventListener('keyup', this.handleKeyUp);
  }

  submissionForm.addEventListener('ajax:success', handleSuccess);
  submissionForm.addEventListener('ajax:error', handleError);

  TimeMe.resetAllRecordedPageTimes();
  TimeMe.startTimer();
}, function () {
  document.removeEventListener('keyup', this.handleKeyUp);
});
